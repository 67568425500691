import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Card, Button, Row, Col, Container } from "shards-react";
import axios from "axios";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";

import useDatabase from "../../hooks/useDatabase";
import { AuthContext } from "../../context/Context";

const StreamCard = ({ checkedRooms, setCheckedRooms }) => {
	const [stream, setStream] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [selectedTags, setSelectedTags] = useState([]); // Ensure selectedTags is initialized as an empty array

	const { user } = useContext(AuthContext);
	const db = useDatabase();
	const userName = user ? user.profile.userName : "";

	// Create stream key
	const createStream = async () => {
		setIsLoading(true);
		try {
			const res = await axios.post(
				"https://us-central1-areabox-chat.cloudfunctions.net/admin/create-stream",
				{},
				{
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
				}
			);
			await setDoc(doc(firestore, "streams", "current"), res.data);
			setStream({ ...res.data, status: "active" });
		} catch (e) {
			console.error("Failed to create live stream:", e);
		} finally {
			setIsLoading(false);
		}
	};

	// save everything
	const handleSave = async () => {
		console.log("🚀 ~ file: StreamCard.js:50 ~ checkedRooms:", checkedRooms);
		if (checkedRooms.length < 1) {
			return;
		}
		let writes = 0;
		checkedRooms.forEach(({ key }) => {
			writes++;
			db.saveDocument(
				key,
				{ title, stream, content, userName, tags: selectedTags },
				checkedRooms.length,
				writes,
				() => {
					setTitle("");
					setContent("");

					setCheckedRooms([]);

					setSelectedTags([]); // Clear tags after saving
				}
			);
		});
	};

	return (
		<Row className="stream-card-containr">
			<Col md="12">
				<Card small className="rounded-0 blue-border">
					<Container className="mt-5 mb-3">
						<input
							type="text"
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							placeholder="TITLE YOUR LIVE STREAM"
							className="form-control mb-3 py-3"
						/>
					</Container>
					<div className="p-4 border">
						<h5 className="font-weight-bold mb-2 barlow">
							ENTER THE KEY SHOWN BELOW INTO OBS STUDIO
						</h5>
						<p className="text-muted barlow mb-5">
							(Download Open Broadcaster Software{" "}
							<a href="https://obsproject.com/download">here</a>)
						</p>

						<section className="border-top border-bottom my-3 py-2">
							<h5 className="font-weight-bold barlow mb-2">
								<span>STREAM KEY:</span> <span>{stream.streamKey || ""}</span>
							</h5>
						</section>

						<div className="text-center">
							<Button
								squared
								onClick={createStream}
								disabled={isLoading}
								className="mb-5 btn-outline-primary blue-btn-outline"
							>
								{isLoading ? (
									<span
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
								) : (
									"GENERATE NEW STREAM KEY"
								)}
							</Button>
						</div>
						<section className="mb-5">
							<h6 className="font-weight-bold barlow">Server:</h6>
							<p className="text-muted mb-5">
								rtmps://global-live.mux.com:443/app
							</p>
						</section>
					</div>
				</Card>
				<Row className="justify-content-center">
					<Col className="text-center">
						<Button
							squared
							onClick={handleSave}
							className="rounded-0 blue-banner scriber-bold px-5"
						>
							PUBLISH
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

StreamCard.propTypes = {
	title: PropTypes.string,
};

StreamCard.defaultProps = {
	title: "Live Stream",
};

export default StreamCard;
