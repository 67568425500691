import React, { useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Nav, Navbar, NavbarBrand } from "shards-react";
import NavbarToggle from "./NavbarToggle";
import AreaBoxLogo from "./../../../assets/areabox-logo.png";
import { AuthContext } from "../../../context/Context";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";
import UserActions from "./NavbarNav/UserActions";

const MainNavbar = ({ stickyTop }) => {
	const classes = classNames(
		"main-navbar",
		"bg-white",
		stickyTop && "sticky-top"
	);

	const { user } = useContext(AuthContext);
	return (
		<>
			<div className={classes}>
				<Navbar type="light" expand="md">
					<NavbarBrand href="#">
						<img
							id="main-logo"
							src={AreaBoxLogo}
							alt="Areabox Dashboard"
							style={{ height: "70px", paddingLeft: "2rem" }}
						/>
					</NavbarBrand>

					<Nav navbar className="mx-auto">
						<h3 className="blue-text scriber-bold mb-0">
							ADMINISTRATOR CONSOLE
						</h3>
					</Nav>
					<Nav navbar className="m-ato">
						<p className="blue-text barlow mb-0">
							{formatPhoneNumber(user?.profile.phoneNo)} as Admin
						</p>
					</Nav>
					<UserActions />
					<NavbarToggle />
				</Navbar>
			</div>
			<style jsx>{`
				.navbar {
					border-bottom: 1px solid #1505cc;
				}
			`}</style>
		</>
	);
};

MainNavbar.propTypes = {
	/**
	 * The layout type where the MainNavbar is used.
	 */
	layout: PropTypes.string,
	/**
	 * Whether the main navbar is sticky to the top, or not.
	 */
	stickyTop: PropTypes.bool,
};

MainNavbar.defaultProps = {
	stickyTop: true,
};

export default MainNavbar;
