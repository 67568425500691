import React from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";
import AreaBoxLogo from "./../../../assets/areabox-logo.png";

import { Dispatcher, Constants } from "../../../flux";

class SidebarMainNavbar extends React.Component {
	constructor(props) {
		super(props);

		this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
	}

	handleToggleSidebar() {
		Dispatcher.dispatch({
			actionType: Constants.TOGGLE_SIDEBAR,
		});
	}

	render() {
		return (
			<div className="main-navbar mt-5">
				<Navbar
					className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
					type="light"
				>
					<NavbarBrand href="#">
						<img
							id="main-logo"
							src={AreaBoxLogo}
							alt="Areabox Dashboard"
							style={{
								paddingLeft: "2rem",
							}}
						/>
					</NavbarBrand>
					<a
						className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
						onClick={this.handleToggleSidebar}
					>
						<i className="material-icons">&#xE5C4;</i>
					</a>
				</Navbar>
				<div className="mt-2"></div>
			</div>
		);
	}
}

SidebarMainNavbar.propTypes = {
	/**
	 * Whether to hide the logo text, or not.
	 */
	hideLogoText: PropTypes.bool,
};

SidebarMainNavbar.defaultProps = {
	hideLogoText: false,
};

export default SidebarMainNavbar;
