import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "shards-react";

const SidebarTags = ({ checkedRooms, selectedTags, setSelectedTags }) => {
	const handleTagChange = (tag) => {
		setSelectedTags((prevTags) =>
			prevTags.includes(tag)
				? prevTags.filter((t) => t !== tag)
				: [...prevTags, tag]
		);
	};

	return (
		<>
			<Card className="rounded-0">
				<CardBody>
					<div>
						<p>Tags</p>
						{checkedRooms &&
							checkedRooms.map(({ tags }, index) => (
								<div key={index}>
									{tags && tags.length ? (
										tags.map((tag) => (
											<div key={tag}>
												<input
													type="checkbox"
													checked={selectedTags.includes(tag)}
													onChange={() => handleTagChange(tag)}
												/>{" "}
												<span>{tag}</span>
											</div>
										))
									) : (
										<p>No tags found</p>
									)}
								</div>
							))}
					</div>
				</CardBody>
			</Card>
		</>
	);
};

SidebarTags.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

SidebarTags.defaultProps = {
	title: "Channels",
};

export default SidebarTags;
