import { get, ref as refD, remove, set, update } from "firebase/database";
import { database } from "../../firebase";

export const handleHideMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);

			await update(messageRef, { isHidden: true });

			console.log(
				`Message ${messageId} has been hidden in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error hiding message:", error);
		}
	});
};

export const handleUnhideMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);

			await update(messageRef, { isHidden: false });

			console.log(
				`Message ${messageId} has been unhidden in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error unhiding message:", error);
		}
	});
};

export const handlePenalizeMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);
			const snapshot = await get(messageRef);
			const currentValue = snapshot.val().isPenalized || 0;

			// If the message has been upranked 3 times, don't uprank it anymore
			if (currentValue === 3) return;

			const newValue = currentValue < 3 ? currentValue + 1 : 3;
			await update(messageRef, { isPenalized: newValue });

			console.log(
				`Message ${messageId} has been penalized in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error penalizing message:", error);
		}
	});
};

export const handleUnpenalizeMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);
			const snapshot = await get(messageRef);
			const currentValue = snapshot.val().isUpranked || 0;

			// If the message has been downranked 3 times, don't downrank it anymore
			if (currentValue === 0) return;

			const newValue = currentValue > 0 ? currentValue - 1 : 0;
			await update(messageRef, { isUpranked: newValue });

			console.log(
				`Message ${messageId} has been unpenalized in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error unpenalizing message:", error);
		}
	});
};

export const handleUprankMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);
			const snapshot = await get(messageRef);
			const currentValue = snapshot.val().isUpranked || 0;

			// If the message has been upranked 3 times, don't uprank it anymore
			if (currentValue === 3) return;

			const newValue = currentValue < 3 ? currentValue + 1 : 3;
			await update(messageRef, { isUpranked: newValue });

			console.log(
				`Message ${messageId} has been upranked to ${newValue} in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error upranking message:", error);
		}
	});
};

export const handleDownrankMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);
			const snapshot = await get(messageRef);
			const currentValue = snapshot.val().isUpranked || 0;

			// If the message has been downranked 3 times, don't downrank it anymore
			if (currentValue === 0) return;

			const newValue = currentValue > 0 ? currentValue - 1 : 0;
			await update(messageRef, { isUpranked: newValue });

			console.log(
				`Message ${messageId} has been downranked in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error downranking message:", error);
		}
	});
};

export const handleTakeDownMessages = async (channelId, selectedMessages) => {
	if (!selectedMessages.length) return;

	selectedMessages.forEach(async (messageId) => {
		try {
			const messageRef = refD(database, `messages/${channelId}/${messageId}`);

			await remove(messageRef);

			console.log(
				`Message ${messageId} has been taken down in channel ${channelId}`
			);
		} catch (error) {
			console.error("Error taking down message:", error);
		}
	});
};

export const handleReplyMessage = async (
	channelId,
	selectedMessages,
	replyText
) => {
	try {
		if (selectedMessages.length > 1)
			throw new Error("Only one message can be replied to");

		selectedMessages.forEach(async (messageId) => {
			try {
				const messageRef = refD(database, `msgComments/${messageId}`);
				const commentData = {
					messageContent: replyText,
					messageType: "text",
					sentAt: Date.now(),
					sentBy: "Admin",
				};
				await set(messageRef, commentData);

				console.log(`Message ${messageId} replied ${channelId}`);
			} catch (error) {
				console.error("Error taking down message:", error);
			}
		});
	} catch (error) {
		console.error(error);
	}
};
