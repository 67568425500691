import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardFooter, CardHeader } from "shards-react";
import { withRouter, Redirect } from "react-router-dom";

import { auth } from "../../firebase";
import { AuthContext } from "../../context/Context";
import { onAuthStateChanged } from "firebase/auth";
import Register from "./Register";
import ArethaSphinx from "../../assets/images/aretha-sphinx.png";
import AreaBoxLogo from "./../../assets/areabox-logo.png";

const LoginPage = ({ history }) => {
	// const store = useStore();
	const [error] = useState("");
	const { user } = useContext(AuthContext);

	// user = true;

	onAuthStateChanged(auth, (user) => {
		if (user) {
			console.log("User logged in:", user);
			// Perform any necessary actions after user login state change
		} else {
			console.log("User logged out");
			// Perform any necessary actions after user logout state change
		}
	});

	// const signInWithGoogle = () => {
	// 	const provider = new GoogleAuthProvider(); //new authInit.GoogleAuthProvider();
	// 	setPersistence(auth, browserSessionPersistence) //firebase.auth().setPersistence(authInit.Auth.Persistence.SESSION)
	// 		.then(() => {
	// 			signInWithPopup(auth, provider) //auth.signInWithPopup(provider)
	// 				.then((res) => {
	// 					const username = getAdditionalUserInfo(res).profile.name; //res.additionalUserInfo.profile.name;
	// 					onValue(child(refD("users"), `${username}`), (snapshot) => {
	// 						//database.ref("users").child(`${username}`).once("value", snapshot => {
	// 						if (snapshot.exists) {
	// 							setUser(snapshot.val());
	// 							setTimeout(() => {
	// 								setLoggedIn(true);
	// 								history.push("/add-new-post");
	// 							}, 0);
	// 						}
	// 					});
	// 				})
	// 				.catch((e) => setErrors(e.message));
	// 		});
	// };

	return (
		<>
			{/* {is2FA && user ? ( */}
			{user ? (
				<Redirect to={{ pathname: "/add-new-post" }} />
			) : (
				<Card className="mb-3 p-5 ml-auto mr-auto blue-border">
					<span>{error}</span>
					<CardHeader className="ml-auto mr-auto text-center">
						<img src={AreaBoxLogo} height="100px" alt="areaboi logo" />
						<h3 className="mt-5 mb-5 text-nowrap blue-text scriber-bold ">
							ADMINISTRATOR CONSOLE
						</h3>
					</CardHeader>
					<CardBody className=" card-padding d-flex justify-content-center">
						<Register history={history} />
					</CardBody>
					<CardFooter>
						<div className="footer scriber-bold">
							<img src={ArethaSphinx} className="footer-img" />
							TM © AreaBox Co.
						</div>
					</CardFooter>
				</Card>
			)}

			<style jsx>{`
				.card-padding {
					padding-left: 50px;
					padding-right: 50px;
				}

				.footer-img {
					height: 40px;
				}
			`}</style>
		</>
	);
};

LoginPage.propTypes = {
	/**
	 * The component's title.
	 */
	title: PropTypes.string,
};

LoginPage.defaultProps = {
	title: "Login",
};

export default withRouter(LoginPage);
