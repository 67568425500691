import React, { useEffect, useState } from "react";
import {
	Card,
	CardHeader,
	CardBody,
	ListGroup,
	ListGroupItem,
} from "shards-react";

import { database } from "../../firebase";
import {
	ref as refD,
	query,
	orderByChild,
	equalTo,
	get,
} from "firebase/database";

function UserPostChannels({ channelId, handleSetChannelInfo }) {
	const [channels, setChannels] = useState([]);
	const [isFetchingChannels, setIsFetchingChannels] = useState(false);

	const fetchChannels = async () => {
		try {
			if (isFetchingChannels) return;
			setIsFetchingChannels(true);
			const channelsQuery = query(
				refD(database, "channels"),
				orderByChild("isPublic"),
				equalTo(true)
			);

			const snapshot = await get(channelsQuery);
			if (snapshot.exists()) {
				const channelsObj = snapshot.val();
				const channelsArr = Object.keys(channelsObj).map((key) => ({
					...channelsObj[key],
					channelId: key,
				}));
				setChannels(channelsArr);
				!channelId &&
					handleSetChannelInfo(
						channelsArr[0].channelId,
						channelsArr[0].channelName
					);
			} else {
				setChannels([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsFetchingChannels(false);
		}
	};

	useEffect(() => {
		fetchChannels();
	}, []);
	return (
		<>
			<Card small className="mb-3 scriber-bold rounded-0 blue-border">
				<CardHeader className="border-bottom">
					<h6 className="m-0 barlow">Public Channels</h6>
				</CardHeader>

				<CardBody className="p-0">
					<ListGroup flush style={{ minHeight: "32rem" }}>
						{channels &&
							channels.map((channel, index) => (
								<ListGroupItem
									key={index}
									style={{ cursor: "pointer" }}
									className="px-3 pb-2 pt-1 mt-1 mb-1"
									onClick={() => {
										handleSetChannelInfo(
											channel.channelId,
											channel.channelName
										);
									}}
								>
									{channel.channelName}
								</ListGroupItem>
							))}
					</ListGroup>
				</CardBody>
			</Card>
		</>
	);
}

export default UserPostChannels;
