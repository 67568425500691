import React, { useState } from "react";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "shards-react";

const MediaSelectorDropdown = ({ mediaType, setMediaType }) => {
	const [open, setOpen] = useState(false);

	const toggle = () => {
		setOpen(!open);
	};

	return (
		<Dropdown open={open} toggle={toggle}>
			<DropdownToggle theme="success">{mediaType.toUpperCase()}</DropdownToggle>
			<DropdownMenu>
				<DropdownItem onClick={() => setMediaType("image")}>Image</DropdownItem>
				<DropdownItem onClick={() => setMediaType("video")}>Video</DropdownItem>
				<DropdownItem onClick={() => setMediaType("video")}>Audio</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default MediaSelectorDropdown;
