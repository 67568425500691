import React from "react";
import PropTypes from "prop-types";
import { NavLink as RouteNavLink } from "react-router-dom";
import { NavItem, NavLink } from "shards-react";

const SidebarNavItem = ({ item }) => (
	<>
		<NavItem>
			<NavLink tag={RouteNavLink} to={item.to} className="bottom-border">
				{item.title && (
					<span className="barlow sidebar-font">{item.title}</span>
				)}
				{item.htmlAfter && (
					<div
						className="d-inline-block item-icon-wrapper"
						dangerouslySetInnerHTML={{ __html: item.htmlAfter }}
					/>
				)}
			</NavLink>
		</NavItem>

		<style jsx>{`
			.sidebar-font {
				font-size: 1.25rem;
				color: black;
			}

			.bottom-border {
				bottom-border: 1px solid #1505cc !important;
			}
		`}</style>
	</>
);

SidebarNavItem.propTypes = {
	/**
	 * The item object.
	 */
	item: PropTypes.object,
};

export default SidebarNavItem;
