import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";

const DefaultLayout = ({ children }) => (
	<div>
		<Row noGutters={true}>
			<Col>
				<MainNavbar />
			</Col>
		</Row>
		<Row>
			<Col className="col-2">
				<MainSidebar />
			</Col>
			<Col>{children}</Col>
		</Row>
	</div>
);

DefaultLayout.propTypes = {
	/**
	 * Whether to display the navbar, or not.
	 */
	noNavbar: PropTypes.bool,
	/**
	 * Whether to display the footer, or not.
	 */
	noFooter: PropTypes.bool,
};

DefaultLayout.defaultProps = {
	noNavbar: false,
	noFooter: false,
};

export default DefaultLayout;
