import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import { onValue, ref, off, get } from "firebase/database";
import StreamCard from "../components/livestream/StreamCard";
import SidebarChannels from "../components/add-new-post/SidebarChannels";
import { database } from "../firebase";
import SidebarTags from "../components/add-new-post/SidebarTags";

const LiveStream = () => {
	const [channels, setChannels] = useState([]);
	const [publicChannels, setPublicChannels] = useState([]);
	const [checkedPublicChannel, setCheckedPublicChannel] = useState([]);
	const [checkedRooms, setCheckedRooms] = useState([]);
	const [tags, setTags] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);

	const loadChannels = () => {
		const refRooms = ref(database, "rooms");
		onValue(refRooms, (snapshot) => {
			const channelsObj = snapshot.val();
			if (channelsObj) {
				const channelsList = Object.keys(channelsObj).map((key) => ({
					...channelsObj[key],
					key,
				}));
				setChannels(channelsList);
			}
		});
		return () => off(refRooms);
	};

	const loadPublicChannels = async () => {
		const channelsRef = ref(database, "channels");
		try {
			const snapshot = await get(channelsRef);
			if (snapshot.exists()) {
				const publicChannelsData = snapshot.val();
				const publicChannelsList = Object.keys(publicChannelsData)
					.map((key) => ({
						...publicChannelsData[key],
						channelId: key,
					}))
					.filter((channel) => channel.isPublic === true);
				setPublicChannels(publicChannelsList);
			} else {
				console.log("No public channels found.");
			}
		} catch (error) {
			console.error("Error retrieving all public channels:", error);
		}
	};

	const handleCheckPublic = ({ channelId, channelName }) => {
		setCheckedPublicChannel(
			checkedPublicChannel.some(
				(publicChannel) => publicChannel.channelId === channelId
			)
				? checkedPublicChannel.filter((c) => c.channelId !== channelId)
				: [...checkedPublicChannel, { channelId, channelName }]
		);
	};

	useEffect(() => {
		// load all channels

		loadChannels();
		loadPublicChannels();
	}, []);

	return (
		<Container fluid className="main-content-container px-4 py-5">
			{/* Page Header */}

			<Row>
				{/* StreamCard */}
				<Col className="col-3">
					<div className="scriber-bold">
						<SidebarChannels
							channels={channels}
							publicChannels={publicChannels}
							handleCheckPublic={handleCheckPublic}
							checkedRooms={checkedRooms}
							setCheckedRooms={setCheckedRooms}
							checkedPublicChannel={checkedPublicChannel}
						/>
					</div>
				</Col>

				<Col lg="6" md="12">
					<Row noGutters className="page-header">
						<Col>
							<button className="blue-banner scriber-bold">LIVE STREAM</button>
						</Col>
					</Row>
					<Row>
						<Col>
							<StreamCard
								checkedRooms={checkedRooms}
								setCheckedRooms={setCheckedRooms}
							/>
						</Col>
					</Row>
				</Col>

				{/* Sidebar Widgets */}
				<Col lg="3" md="12">
					<Row>
						<Col>
							<button className="blue-banner scriber-bold">
								CATEGORIZE/TAG
							</button>
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="blue-border scriber-bold">
								<SidebarTags
									channels={channels}
									publicChannels={publicChannels}
									handleCheckPublic={handleCheckPublic}
									checkedRooms={checkedRooms}
									checkedPublicChannel={checkedPublicChannel}
									tags={tags}
									selectedTags={selectedTags}
									setSelectedTags={setSelectedTags}
									setTags={setTags} // Pass the tag change handler
								/>
							</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);
};

export default LiveStream;
