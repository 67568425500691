import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container } from "shards-react";

import { database } from "../../firebase";
import { query, ref as refD, get, update } from "firebase/database";
import { handleTakeDownMessages } from "./user-post-services";
import useSelectedMessagesStore from "../../store/selectedMessagesStore";

const ACTION_TYPE = {
	MUTE: "mute",
	UNMUTE: "unmute",
	SHADOWBAN: "shadowban",
	UNSHADOWBAN: "unshadowban",
	BAN: "ban",
	UNBAN: "unban",
};

const UserPostUsers = ({ channelName, channelId }) => {
	const [isFetchingUsers, setIsFetchingUsers] = useState(false);
	const [isMutingUsers, setIsMutingUsers] = useState(false);
	const [isShadowBanningUsers, setIsShadowBanningUsers] = useState(false);
	const [isBanningUsers, setIsBanningUsers] = useState(false);
	const [isUnMutingUsers, setIsUnMutingUsers] = useState(false);
	const [isUnShadowBanningUsers, setIsUnShadowBanningUsers] = useState(false);
	const [isUnBanningUsers, setIsUnBanningUsers] = useState(false);
	const [channelUsers, setChannelUsers] = useState([]);
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [isTakingDownMessages, setIsTakingDownMessages] = useState(false);
	const selectedMessages = useSelectedMessagesStore(
		(state) => state.selectedMessages
	);
	const setSelectedMessages = useSelectedMessagesStore(
		(state) => state.setSelectedMessages
	);

	const addOrRemoveSelectedUsers = (username) => {
		setSelectedUsers((prevSelectedUsers) => {
			if (prevSelectedUsers.includes(username)) {
				// Remove the username if it's already in the list
				return prevSelectedUsers.filter((user) => user !== username);
			} else {
				// Add the username if it's not in the list
				return [...prevSelectedUsers, username];
			}
		});
		// console.log(username);
		// console.log(selectedUsers);
	};

	const handleMuteUser = (actionType, username) => {
		let action;
		if (actionType === ACTION_TYPE.MUTE) {
			action = true;
		} else if (actionType === ACTION_TYPE.UNMUTE) {
			action = false;
		} else {
			return;
		}
		const userStatusRef = refD(
			database,
			`channelSubscribers/${channelId}/${username}`
		);
		update(userStatusRef, { isMuted: action })
			.then(() => {
				console.log(
					`User ${username} has been ${action} in channel ${channelId}`
				);
			})
			.catch((error) => {
				console.error("Error muting user:", error);
			})
			.finally(() => {
				setSelectedUsers([]);
				handleFetchChannelUsers();
			});
	};
	const handleShadowBanUser = (actionType, username) => {
		let action;
		if (actionType === ACTION_TYPE.SHADOWBAN) {
			action = true;
		} else if (actionType === ACTION_TYPE.UNSHADOWBAN) {
			action = false;
		} else {
			return;
		}
		const userStatusRef = refD(
			database,
			`channelSubscribers/${channelId}/${username}`
		);
		update(userStatusRef, { isShadowBanned: action })
			.then(() => {
				console.log(
					`User ${username} has been ${action} in channel ${channelId}`
				);
			})
			.catch((error) => {
				console.error("Error muting user:", error);
			})
			.finally(() => {
				setSelectedUsers([]);
				handleFetchChannelUsers();
			});
	};
	const handleBanUser = (actionType, username) => {
		let action;
		if (actionType === ACTION_TYPE.BAN) {
			action = true;
		} else if (actionType === ACTION_TYPE.UNBAN) {
			action = false;
		} else {
			return;
		}
		const userStatusRef = refD(
			database,
			`channelSubscribers/${channelId}/${username}`
		);
		update(userStatusRef, { isBanned: action })
			.then(() => {
				console.log(
					`User ${username} has been ${action} in channel ${channelId}`
				);
			})
			.catch((error) => {
				console.error("Error muting user:", error);
			})
			.finally(() => {
				setSelectedUsers([]);
				handleFetchChannelUsers();
			});
	};

	const handleMuteUnMuteUsers = (actionType) => {
		if (!selectedUsers.length) return;

		if (actionType === ACTION_TYPE.MUTE) {
			setIsMutingUsers(true);
		} else if (actionType === ACTION_TYPE.UNMUTE) {
			setIsUnMutingUsers(true);
		} else return;

		selectedUsers.forEach((username) => {
			handleMuteUser(actionType, username);
		});

		if (actionType === ACTION_TYPE.MUTE) {
			setIsMutingUsers(false);
		} else if (actionType === ACTION_TYPE.UNMUTE) {
			setIsUnMutingUsers(false);
		}
	};
	const handleShadowBanUnShadowBanUsers = (actionType) => {
		if (!selectedUsers.length) return;

		if (actionType === ACTION_TYPE.SHADOWBAN) {
			setIsShadowBanningUsers(true);
		} else if (actionType === ACTION_TYPE.UNSHADOWBAN) {
			setIsUnShadowBanningUsers(true);
		} else return;

		selectedUsers.forEach((username) => {
			handleShadowBanUser(actionType, username);
		});

		if (actionType === ACTION_TYPE.SHADOWBAN) {
			setIsShadowBanningUsers(false);
		} else if (actionType === ACTION_TYPE.UNSHADOWBAN) {
			setIsUnShadowBanningUsers(false);
		}
	};
	const handleBanUnBanUsers = (actionType) => {
		if (!selectedUsers.length) return;

		if (actionType === ACTION_TYPE.BAN) {
			setIsBanningUsers(true);
		} else if (actionType === ACTION_TYPE.UNBAN) {
			setIsUnBanningUsers(true);
		} else return;

		selectedUsers.forEach((username) => {
			handleBanUser(actionType, username);
		});

		if (actionType === ACTION_TYPE.BAN) {
			setIsBanningUsers(false);
		} else if (actionType === ACTION_TYPE.UNBAN) {
			setIsUnBanningUsers(false);
		}
	};

	const handleFetchChannelUsers = async () => {
		try {
			if (isFetchingUsers || !channelId) return;
			setIsFetchingUsers(true);

			const userssQuery = query(
				refD(database, `channelSubscribers/${channelId}`)
			);

			const snapshot = await get(userssQuery);
			if (snapshot.exists()) {
				const channelSubscribersObj = snapshot.val();
				// console.log(channelSubscribersObj);
				const channelSubscribersArr = Object.keys(channelSubscribersObj).map(
					(key) => ({
						...channelSubscribersObj[key],
						username: key,
					})
				);
				setChannelUsers(channelSubscribersArr);
				// console.log(channelUsers);
			} else {
				setChannelUsers([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsFetchingUsers(false);
		}
	};

	useEffect(() => {
		handleFetchChannelUsers();
	}, [channelId]);
	return (
		<>
			<Card small className="mb-3 scriber-bold rounded-0 blue-border">
				<CardHeader>
					<h5 className="barlow">{channelName} Users</h5>
				</CardHeader>
				<CardBody>
					{channelUsers.map((user, index) => (
						<div key={index} className="d-flex align-items-center mb-3">
							<label
								className="mb-0 mr-2 channelUsersLabel"
								htmlFor="publicChannelUsers"
							>
								<input
									type="checkbox"
									id="publicChannelUsers"
									name="publicChannelUsers"
									onChange={() => {
										addOrRemoveSelectedUsers(user.username);
									}}
									checked={selectedUsers.includes(user.username)}
								/>
							</label>
							<h6
								style={{
									fontWeight: 700,
									fontSize: "14px",
									marginBottom: "1px",
									width: "100%",
								}}
							>
								{user.username}
								<span className="userPost-moderation-indicator">
									{user.isMuted && <span>M</span>}
									{user.isShadowBanned && <span>SB</span>}
									{user.isBanned && <span>B</span>}
								</span>
							</h6>
						</div>
					))}
				</CardBody>
			</Card>
			<div className="barlow fixed-bottom-container">
				<Container className="d-flex justify-content-between align-items-center gap-3 p-0">
					<div className="userPost-userActions">
						<button
							className="moderation-button"
							onClick={() => {
								handleMuteUnMuteUsers(ACTION_TYPE.MUTE);
							}}
							disabled={isMutingUsers}
						>
							{isMutingUsers ? "Loading" : "Mute"}
						</button>
						<button
							className=" moderation-button"
							onClick={() => {
								handleMuteUnMuteUsers(ACTION_TYPE.UNMUTE);
							}}
							disabled={isUnMutingUsers}
						>
							{isUnMutingUsers ? "Loading" : "UnMute"}
						</button>
					</div>
					<div className="userPost-userActions">
						<button
							className=" moderation-button"
							onClick={() => {
								handleShadowBanUnShadowBanUsers(ACTION_TYPE.SHADOWBAN);
							}}
							disabled={isShadowBanningUsers}
						>
							{isShadowBanningUsers ? "Loading" : "ShadowBan"}
						</button>
						<button
							className=" moderation-button"
							onClick={() => {
								handleShadowBanUnShadowBanUsers(ACTION_TYPE.UNSHADOWBAN);
							}}
							disabled={isUnShadowBanningUsers}
						>
							{isUnShadowBanningUsers ? "Loading" : "UnBan"}
						</button>
					</div>
					<div className="userPost-userActions">
						<button
							className=" moderation-button"
							onClick={() => {
								handleBanUnBanUsers(ACTION_TYPE.BAN);
							}}
							disabled={isBanningUsers}
						>
							{isBanningUsers ? "Loading" : "Ban"}
						</button>
						<button
							className=" moderation-button"
							onClick={() => {
								handleBanUnBanUsers(ACTION_TYPE.UNBAN);
							}}
							disabled={isUnBanningUsers}
						>
							{isUnBanningUsers ? "Loading" : "UnBan"}
						</button>
					</div>
				</Container>
				<Container className="mt-1 p-0">
					<div className="userPost-userActions">
						<button
							className="full-width-button py-2 moderation-button"
							onClick={async () => {
								setIsTakingDownMessages(true);
								await handleTakeDownMessages(channelId, selectedMessages);
								setIsTakingDownMessages(false);
								setSelectedMessages([]);
								// handleFetchChannelMessages();
							}}
							disabled={isTakingDownMessages}
						>
							{isTakingDownMessages ? "Loading" : "Take Down"}
						</button>
					</div>
				</Container>
			</div>
		</>
	);
};

export default UserPostUsers;
