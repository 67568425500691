import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

const AuthLayout = ({ children }) => (
	<Container fluid>
		<Row>
			{/* <MainSidebar /> */}
			<Col
				className="main-content p-0"
				lg={{ size: 12 }}
				md={{ size: 9 }}
				sm="12"
				tag="main"
			>
				{children}
			</Col>
		</Row>
	</Container>
);

AuthLayout.propTypes = {
	/**
	 * Whether to display the navbar, or not.
	 */
	noNavbar: PropTypes.bool,
	/**
	 * Whether to display the footer, or not.
	 */
	noFooter: PropTypes.bool,
};

AuthLayout.defaultProps = {
	noNavbar: true,
	noFooter: false,
};

export default AuthLayout;
