import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

const useSelectedMessagesStore = create(
	persist(
		(set) => ({
			selectedMessages: [],
			setSelectedMessages: (selectedMessages) =>
				set({ selectedmessages: selectedMessages }),
		}),
		{
			name: "selectedmessages-storage",
			storage: createJSONStorage(() => sessionStorage),
		}
	)
);

export default useSelectedMessagesStore;
