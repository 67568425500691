import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";
import UserPostMessages from "../components/user-posts/UserPostMessages.jsx";
import UserPostChannels from "../components/user-posts/UserPostChannels.jsx";
import UserPostUsers from "../components/user-posts/UserPostUsers";

const UserModeration = () => {
	const [channelId, setChannelId] = useState("");
	const [channelName, setChannelName] = useState("");

	const handleSetChannelInfo = (id, name) => {
		setChannelId(id);
		setChannelName(name);
	};

	return (
		<Container className="px-4 py-5">
			<Row className="mt-4">
				<Col lg="3" md="12">
					<button className="blue-banner scriber-bold">USER MODERATION</button>
				</Col>
				<Col lg="5" md="12">
					<button className="blue-banner scriber-bold">
						MESSAGES MODERATION
					</button>
				</Col>
				<Col lg="3" md="12">
					<button className="blue-banner scriber-bold">CHANNELS</button>
				</Col>
			</Row>
			<Row className="flex-nowrap">
				{/* Manager */}
				<Col lg="3" md="12" className="position-relative">
					<UserPostUsers channelId={channelId} channelName={channelName} />
				</Col>

				<Col lg="5" md="12">
					<UserPostMessages
						channelId={channelId}
						channelName={channelName}
						// messages={messages}
					/>
				</Col>

				{/* Sidebar Widgets */}
				<Col lg="3" md="12">
					<UserPostChannels
						handleSetChannelInfo={handleSetChannelInfo}
						channelId={channelId}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default UserModeration;
