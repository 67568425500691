export const formatPhoneNumber = (phoneNumberString) => {
	// Remove all non-numeric characters
	const cleaned = ("" + phoneNumberString).replace(/\D/g, "");

	// break the string up into 3, 3,3 ,4 substrings
	const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);

	if (match) {
		return `${match[1]}-${match[2]}-${match[3]}-${match[4]}`; // Reformat the string
	}

	return phoneNumberString; // Return the original string if it doesn't match the pattern
};
