import React from "react";
import { Container } from "shards-react";
import LoginPage from "../components/login";

const Login = () => (
	<Container
		fluid
		className="main-content-container px-4 pb-4 d-flex align-items-center justify-content-center"
		style={{ height: "100vh" }}
	>
		<LoginPage />
	</Container>
);

export default Login;
