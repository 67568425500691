import React from "react";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "shards-react";

const CustomModal = ({
	modalOpen,
	modalTitle,
	children,
	affirmText,
	action,
	closeModal,
}) => (
	<>
		<div>
			<Modal centered open={modalOpen}>
				<ModalHeader className="scriber-bold">{modalTitle}</ModalHeader>
				<ModalBody>{children}</ModalBody>
				<ModalFooter>
					<button
						onClick={closeModal}
						type="button"
						className="btn btn-secondary scriber-bold blue-banner grey"
						data-dismiss="modal"
					>
						CLOSE
					</button>
					<button
						onClick={action}
						type="button"
						className="btn btn-primary blue-banner scriber-bold"
					>
						{affirmText.toUpperCase()}
					</button>
				</ModalFooter>
			</Modal>
		</div>
		<style jsx>{`
			.modal {
				background-color: rgba(0, 0, 0, 0.5);
			}
			.modal-content {
				border-radius: 0;
				border: 3px solid blue;
			}
		`}</style>
	</>
);

export default CustomModal;
