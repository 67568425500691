import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import routes from "./routes";
import publicRoutes from "./publicRoutes";

import { AuthContext } from "./context/Context";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/shards-dashboards.1.1.0.min.css";
import "./style/index.css";

const App = () => {
	const { isLoggedIn, user } = useContext(AuthContext);

	const renderProtectedRoute = (route) => {
		if (!isLoggedIn) {
			return <Redirect to="/login" />;
		}
		if (!route.allowedRoles || route.allowedRoles.includes(user?.role)) {
			return (
				<Route
					key={route.path}
					path={route.path}
					exact={route.exact}
					render={(props) => (
						<route.layout {...props}>
							<route.component {...props} />
						</route.layout>
					)}
				/>
			);
		}
		// If user doesn't have permission, redirect to a restricted page
		return <Redirect to="/restrict" />;
	};

	return (
		<Router>
			<>
				<div>
					{publicRoutes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							exact={route.exact}
							render={(props) => (
								<route.layout {...props}>
									<route.component {...props} />
								</route.layout>
							)}
						/>
					))}
					{routes.map((route) => renderProtectedRoute(route))}
				</div>
			</>
		</Router>
	);
};

export default App;
