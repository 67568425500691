import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import Editor from "../components/add-new-post/Editor";
import SidebarChannels from "../components/add-new-post/SidebarChannels";
import SidebarTags from "../components/add-new-post/SidebarTags";
import { database } from "../firebase";
import { onValue, ref, off, get } from "firebase/database";

const AddNewPost = () => {
	const [channels, setChannels] = useState([]);
	const [checkedRooms, setCheckedRooms] = useState([]);
	const [checkedPublicChannel, setCheckedPublicChannel] = useState([]);
	const [publicChannels, setPublicChannels] = useState([]);
	const [tags, setTags] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]); // New state for tags

	const refRooms = ref(database, "rooms");

	const handleCheckPublic = ({ channelId, channelName }) => {
		setCheckedPublicChannel(
			checkedPublicChannel.some(
				(publicChannel) => publicChannel.channelId === channelId
			)
				? checkedPublicChannel.filter((c) => c.channelId !== channelId)
				: [...checkedPublicChannel, { channelId, channelName }]
		);
	};

	const loadChannels = () => {
		onValue(refRooms, (snapshot) => {
			const channelsObj = snapshot.val();
			if (channelsObj) {
				const channelsList = Object.keys(channelsObj).map((key) => ({
					...channelsObj[key],
				}));
				setChannels(channelsList);
				// console.log("channelsList", channelsList);
			}
		});
	};

	const loadPublicChannels = async () => {
		const channelsRef = ref(database, "channels");
		try {
			const snapshot = await get(channelsRef);
			if (snapshot.exists()) {
				const publicChannelsData = snapshot.val();
				const publicChannelsList = Object.keys(publicChannelsData)
					.map((key) => ({
						...publicChannelsData[key],
						channelId: key,
					}))
					.filter((channel) => channel.isPublic === true);
				setPublicChannels(publicChannelsList);
			} else {
				console.log("No public channels found.");
			}
		} catch (error) {
			console.error("Error retrieving all public channels:", error);
		}
	};

	useEffect(() => {
		loadChannels();
		loadPublicChannels();
		return () => off(refRooms);
	}, []);

	return (
		<>
			<Container className="mt-5">
				<Row>
					<Col className="col-3">
						<div className="scriber-bold">
							<SidebarChannels
								channels={channels}
								publicChannels={publicChannels}
								handleCheckPublic={handleCheckPublic}
								checkedRooms={checkedRooms}
								setCheckedRooms={setCheckedRooms}
								checkedPublicChannel={checkedPublicChannel}
							/>
						</div>
					</Col>
					<Col>
						<Row>
							<Col>
								<button className="blue-banner scriber-bold">
									CREATE A POST
								</button>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="scriber-bold">
									<Editor
										checkedRooms={checkedRooms}
										setCheckedRooms={setCheckedRooms}
										checkedPublicChannel={checkedPublicChannel}
										setCheckedPublicChannel={setCheckedPublicChannel}
										channels={channels}
										tags={tags}
										setTags={setTags} // Pass the tag change handler
										selectedTags={selectedTags}
										setSelectedTags={setSelectedTags}
									/>
								</div>
							</Col>
						</Row>
					</Col>
					<Col className="col-3">
						<Row>
							<Col>
								<button className="blue-banner scriber-bold">
									CATEGORIZE/TAG
								</button>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="blue-border scriber-bold">
									<SidebarTags
										channels={channels}
										publicChannels={publicChannels}
										handleCheckPublic={handleCheckPublic}
										checkedRooms={checkedRooms}
										checkedPublicChannel={checkedPublicChannel}
										tags={tags}
										selectedTags={selectedTags}
										setSelectedTags={setSelectedTags}
										setTags={setTags} // Pass the tag change handler
									/>
								</div>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>

			<style jsx>{``}</style>
		</>
	);
};

export default AddNewPost;
